import { useEffect, useRef, useState } from 'react';
import { useAuthContext } from '../../hooks/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet-async';
import style from './style.module.scss';
import { displayWarning } from "../../utils/functions/messageToast";
import { toRespondTickets } from '../../utils/functions/functionsLoadDataForm';
import { InputsInfosPages } from '../../components/ui/inputs';

export default function Pendency() {
  const { authorizationRA, user } = useAuthContext();
  const ambient = user?.ambient as string;
  const [loadingButton, setLoadingButton] = useState(false);
  const [csvData, setCsvData] = useState<any[]>([]);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [textAreaValue, setTextAreaValue] = useState<string>('');
  const [protocolData, setProtocolData] = useState<any[]>([]);
  const [pendency, setPendency] = useState<string>('');

  const handleTextAreaValue = async (value: string) => {
    const lines = value.split('\n').map(line => ({ protocol: line }));
    setProtocolData(lines);
  }

  const adjustHeight = () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    adjustHeight();
  }, [csvData]);

  const handlePendency = async () => {
    setLoadingButton(true);
    await new Promise((resolve) => setTimeout(resolve, 3000));
    const updatedProtocolData = protocolData.map(item => ({
      ...item,
      text: pendency,
    }));
    await toRespondTickets(ambient, authorizationRA, updatedProtocolData, setCsvData);
    setPendency('');
    setTextAreaValue('');
    setLoadingButton(false);
    setIsModalOpen(false);
  };

  return (
    <div>
      <Helmet>
        <title>Tech Forms - Responder Pendência em massa</title>
      </Helmet>

      <div className={style.container}>
        <div className={style.containerMain}>

          <div className={style.items}>

            <button
              className={style.buttonSend}
              onClick={async () => {
                if (!textAreaValue) {
                  displayWarning("Nenhum protocolo informado");
                  return;
                }
                setIsModalOpen(true)
              }}
            >
              {!loadingButton ? (
                <a>Responder</a>
              ) : (
                <FontAwesomeIcon icon={faSpinner} spin />
              )}
            </button>

            <button
              className={style.buttonClear}
              onClick={() => {
                setTextAreaValue('');
              }}
            >
              Limpar
            </button>

            <InputsInfosPages
              placeholder='Resposta da pendencia'
              type='text'
              value={pendency}
              style={{ width: '80%', height: '40px' }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setPendency(event.target.value)
              }
            />
          </div>
        </div>

        <h1 className={style.divTextArea}>Protocolos</h1>
        <textarea
          // value={JSON.stringify(csvData, null, 2)}
          value={textAreaValue}
          className={style.jsonTextarea}
          placeholder=""
          onChange={(e) => {
            setTextAreaValue(e.target.value);
            handleTextAreaValue(e.target.value);
          }}
        />

        {isModalOpen && (
          <div className={style.modal}>
            <div className={style.modalContent}>
              <p>Confirma a tabulação em massa?</p>
              <div className={style.modalActions}>
                <button
                  className={style.modalButton}
                  onClick={handlePendency}
                  disabled={loadingButton}
                >
                  {loadingButton ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Confirmar'}
                </button>
                <button
                  className={style.modalButton}
                  onClick={() => setIsModalOpen(false)} // Fecha o modal
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        )}

      </div>
    </div>
  );
}
