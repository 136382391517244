import axios, { AxiosError } from "axios";
import { AuthTokenError } from "./errors/authTokenError";

export function setupApiSaveAndPublishForm(
  authContext: any,
  authorization: string
) {
  const api = axios.create({
    baseURL:
      import.meta.env.MODE === "production"
        ? import.meta.env.VITE_BASE_URL_PROD
        : import.meta.env.VITE_BASE_URL_DEV,
    headers: {
      Authorization: authorization,
    },
    maxBodyLength: Infinity,
  });
  api.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      if (error.response?.status === 401) {
        if (typeof window !== "undefined") {
          authContext?.signOut();
        } else {
          return Promise.reject(new AuthTokenError());
        }
      }
      return Promise.reject(error);
    }
  );
  return api;
}

export function setupApiGetTabAndSkill(
  authContext: any,
  authorization: string
) {
  const api = axios.create({
    baseURL:
      import.meta.env.MODE === "production"
        ? import.meta.env.VITE_BASE_URL_PROD
        : import.meta.env.VITE_BASE_URL_DEV,
    headers: {
      Authorization: authorization,
    },
    maxBodyLength: Infinity,
  });
  api.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      if (error.response?.status === 401) {
        if (typeof window !== "undefined") {
          authContext?.signOut();
        } else {
          return Promise.reject(new AuthTokenError());
        }
      }
      return Promise.reject(error);
    }
  );
  return api;
}

export function setupApiGetFormsAndLogin(ctx = undefined, authContext: any) {
  const api = axios.create({
    baseURL:
      import.meta.env.MODE === "production"
        ? import.meta.env.VITE_BASE_URL_PROD
        : import.meta.env.VITE_BASE_URL_DEV,
  });
  api.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      if (error.response?.status === 401) {
        if (typeof window !== "undefined") {
          authContext?.signOut();
        } else {
          return Promise.reject(new AuthTokenError());
        }
      }
      return Promise.reject(error);
    }
  );
  return api;
}

export function setupApiGetAllForms(authContext: any, authorization: string) {
  const api = axios.create({
    baseURL:
      import.meta.env.MODE === "production"
        ? import.meta.env.VITE_BASE_URL_PROD
        : import.meta.env.VITE_BASE_URL_DEV,
    headers: {
      Authorization: authorization,
    },
    maxBodyLength: Infinity,
  });
  api.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      if (error.response?.status === 401) {
        if (typeof window !== "undefined") {
          authContext?.signOut();
        } else {
          return Promise.reject(new AuthTokenError());
        }
      }
      return Promise.reject(error);
    }
  );
  return api;
}

export function setupApiCreateTemplate(ctx = undefined, authContext: any) {
  const api = axios.create({
    baseURL:
      import.meta.env.MODE === "production"
        ? import.meta.env.VITE_BASE_URL_PROD
        : import.meta.env.VITE_BASE_URL_DEV,
  });
  api.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      if (error.response?.status === 401) {
        if (typeof window !== "undefined") {
          authContext?.signOut();
        } else {
          return Promise.reject(new AuthTokenError());
        }
      }
      return Promise.reject(error);
    }
  );
  return api;
}

export function setupApiTabulateTickets(
  authContext: any,
  authorization: string
) {
  const api = axios.create({
    baseURL:
      import.meta.env.MODE === "production"
        ? import.meta.env.VITE_BASE_URL_PROD
        : import.meta.env.VITE_BASE_URL_DEV,
    headers: {
      Authorization: authorization,
    },
    maxBodyLength: Infinity,
  });
  api.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      if (error.response?.status === 401) {
        if (typeof window !== "undefined") {
          authContext?.signOut();
        } else {
          return Promise.reject(new AuthTokenError());
        }
      }
      return Promise.reject(error);
    }
  );
  return api;
}

export function setupApiCreateSkills(authContext: any, authorization: string) {
  const api = axios.create({
    baseURL:
      import.meta.env.MODE === "production"
        ? import.meta.env.VITE_BASE_URL_PROD
        : import.meta.env.VITE_BASE_URL_DEV,
    headers: {
      Authorization: authorization,
    },
    maxBodyLength: Infinity,
  });
  api.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      if (error.response?.status === 401) {
        if (typeof window !== "undefined") {
          authContext?.signOut();
        } else {
          return Promise.reject(new AuthTokenError());
        }
      }
      return Promise.reject(error);
    }
  );
  return api;
}
