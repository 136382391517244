import { SelectHTMLAttributes } from 'react'
import styles from './styles.module.scss'

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> { }

export function Select({ children, ...rest }: SelectProps) {
  return (
    <select className={styles.select} {...rest}>
      {children}
    </select>
  )
}

export function SelectAmbient({ children, ...rest }: SelectProps) {
  return (
    <select className={styles.select} {...rest}>
      {children}
    </select>
  )
}

export function SelectFieldPersonalDetails({ ...rest }: SelectProps) {
  return (
    <select title='Adicionar Campos Customizados' className={styles.selectField} {...rest}>
      <option value='' disabled>Customer</option>
      <option value='customerSocialNumber'>Document</option>
      <option value='customerEmail'>Email</option>
      <option value='customerPhoneNumber'>Phone</option>
      <option value='customerName'>Name</option>
    </select>
  )
}