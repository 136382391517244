import { Link, Outlet } from 'react-router-dom';
import style from './style.module.scss';
import { Helmet } from 'react-helmet-async';
import { Header } from '../../components/Header';
import { useState } from 'react';

export default function Home() {
  const [activeButton, setActiveButton] = useState("");

  const sectionOptions = [
    { href: "listForms", label: 'Listar Formulários' },
    { href: "createForm", label: 'Criar novo formulário' },
    { href: "createTemplate", label: 'Criar form pelo payload' },
    { href: "tabulation", label: 'Tabulação em massa' },
    { href: "createSkills", label: 'Criar Skills' },
    { href: "pendency", label: 'Responder pendencias em massa' }
  ];

  const sectionOptionsTests = [
    { href: "deploy", label: 'Deploy' },
    { href: "updateForm", label: 'Atualizar formulário' }
  ]

  const handleButtonClick = (href: string) => {
    setActiveButton(href);
  };

  return (
    <>
      <Helmet>
        <title>Tech Forms - Tabulação em massa</title>
      </Helmet>

      <Header />
      <div className={style.mainContainer}>

        <aside className={style.sectionContainer}>
          {sectionOptions.map((button) => (
            <Link to={button.href} key={button.label}>
              <button
                onClick={() => handleButtonClick(button.href)}
                className={`${style.button} ${activeButton === button.href ? style.buttonActive : ''
                  }`}
              >
                {button.label}
              </button>
            </Link>
          ))}

          {import.meta.env.MODE !== "production" && (
            <>
              <h3>Testes</h3>
              {sectionOptionsTests.map((button) => (
                <Link to={button.href} key={button.label}>
                  <button
                    onClick={() => handleButtonClick(button.href)}
                    className={`${style.button} ${activeButton === button.href ? style.buttonActive : ''
                      }`}
                  >
                    {button.label}
                  </button>
                </Link>
              ))}
            </>
          )}

        </aside>

        <main className={style.contentContainer}>
          <Outlet />
        </main>

      </div>
    </>
  );
}